import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 886.000000 886.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,886.000000) scale(0.100000,-0.100000)">
					<path d="M1873 5003 c-40 -14 -84 -55 -137 -130 l-23 -33 -44 85 c-24 47 -47
85 -50 85 -6 0 -67 -98 -78 -125 -2 -5 -8 -13 -13 -16 -8 -5 37 -99 79 -167
18 -29 19 -25 -237 -457 -83 -138 -101 -175 -94 -187 4 -6 3 -8 -4 -4 -17 10
-26 -80 -13 -125 7 -22 16 -37 21 -34 6 3 8 0 5 -7 -3 -8 10 -20 31 -30 28
-14 57 -18 147 -16 l112 2 -110 6 c-107 6 -108 6 -35 9 62 3 69 5 40 11 -26 6
-29 8 -11 9 48 2 10 14 -41 12 -28 -1 -49 1 -46 4 3 3 28 6 56 6 29 1 52 6 52
12 0 5 5 5 13 -2 15 -12 27 -5 27 14 0 7 -15 16 -32 20 -24 5 -28 9 -15 12 18
5 18 5 0 19 -15 11 -15 13 -2 14 8 0 20 -5 27 -12 9 -9 12 -9 12 -1 0 7 21 14
48 16 33 2 25 4 -30 5 -60 2 -77 -1 -80 -13 -2 -9 -8 -12 -16 -6 -12 7 -11 14
2 40 9 17 14 37 10 43 -4 6 -3 8 3 5 7 -5 114 163 132 207 2 6 8 15 13 21 4 5
32 50 62 99 29 50 57 91 62 93 5 2 15 -10 22 -27 8 -17 18 -27 24 -24 6 4 8 2
5 -3 -4 -6 5 -32 20 -59 23 -41 28 -45 35 -28 5 13 6 9 2 -13 -3 -17 -1 -33 3
-36 10 -7 15 29 8 59 -3 13 -12 21 -22 20 -12 -1 -19 7 -20 24 -1 16 0 18 4 7
2 -10 11 -16 18 -13 7 2 16 2 20 -2 4 -4 26 -7 49 -8 38 0 43 2 39 20 -3 15 2
11 17 -12 33 -49 34 -33 1 29 -16 30 -51 96 -78 147 l-48 93 46 87 c26 48 54
88 63 90 11 2 26 -18 48 -65 18 -37 41 -76 50 -86 9 -11 22 -32 28 -48 7 -16
20 -29 29 -29 10 0 12 3 5 8 -10 6 -44 65 -44 75 0 3 6 5 13 4 36 -1 49 4 44
17 -3 8 1 17 9 20 8 3 14 0 14 -7 0 -7 5 -4 11 6 15 25 25 21 45 -20 9 -21 19
-33 21 -27 2 7 -15 45 -39 85 -24 41 -50 88 -58 104 -8 17 -21 34 -27 38 -7 4
-13 14 -13 22 0 17 -56 60 -90 70 -49 13 -55 13 -97 -2z m245 -191 c8 -17 9
-28 2 -30 -5 -2 -10 -13 -10 -24 0 -31 -25 -15 -27 18 -1 16 0 23 3 17 8 -18
24 -16 24 2 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 24 14 17 28 -13z m-238
-313 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z
m-130 1 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m145 -30 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-77 -31 c19 -30 8 -34 -12 -4 -9 14 -13 25 -9 25 5 0 14 -9 21 -21z
m-355 -333 c-3 -4 -9 -2 -14 5 -13 22 -11 31 5 17 8 -7 12 -17 9 -22z"/>
					<path d="M3920 4686 l0 -73 50 1 c37 2 50 -2 53 -14 3 -8 5 24 6 73 l1 87 -55
0 -55 0 0 -74z"/>
					<path d="M2984 4663 c-13 -29 -24 -56 -24 -58 0 -3 16 -5 36 -5 19 0 42 -7 50
-15 22 -22 37 -19 24 5 -15 27 4 25 23 -2 12 -17 16 -19 16 -7 1 8 -4 20 -11
27 -9 9 -9 12 0 12 7 0 17 -10 22 -22 9 -23 9 -23 9 2 1 21 4 19 22 -18 12
-24 23 -41 25 -40 1 2 -12 43 -29 91 l-32 87 -54 -2 -54 -2 -23 -53z"/>
					<path d="M5943 4665 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
					<path d="M5960 4665 c0 -23 3 -27 10 -15 8 13 10 13 10 1 0 -11 6 -9 20 7 28
30 25 43 -10 40 -26 -2 -30 -6 -30 -33z"/>
					<path d="M6033 4660 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
					<path d="M2072 4670 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M2171 4654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2205 4660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M2090 4581 c0 -19 50 -104 57 -97 3 3 1 12 -6 20 -6 8 -20 31 -31 52
-11 22 -19 33 -20 25z"/>
					<path d="M3553 4563 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
					<path d="M4628 4563 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
					<path d="M3490 4550 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
					<path d="M3610 4556 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z" />
					<path d="M4118 4553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M2822 4288 c-33 -78 -64 -151 -67 -160 -6 -15 0 -17 51 -15 l58 2 25
63 26 64 141 2 141 3 28 -63 c18 -41 35 -64 49 -68 35 -9 96 -7 96 4 0 10 -21
13 -85 12 -21 -1 -24 1 -10 5 30 8 47 24 41 40 -3 7 -1 13 5 13 7 0 9 -10 5
-25 -4 -14 -2 -25 4 -25 5 0 10 6 10 13 0 8 4 7 10 -3 5 -8 10 -10 10 -5 0 6
-4 15 -8 20 -5 6 -19 35 -31 65 -47 114 -74 169 -80 166 -3 -2 -27 -1 -53 4
-49 7 -55 2 -38 -29 8 -16 5 -20 -22 -25 -38 -8 -131 -8 -151 0 -14 5 -14 10
-1 40 14 33 14 34 -10 34 -13 0 -37 3 -53 6 l-29 6 -62 -144z m448 -114 c0 -8
-4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m17 -16 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
					<path d="M3356 4368 c-11 -44 -6 -81 20 -135 52 -106 162 -157 263 -122 39 14
42 39 4 38 -24 0 -25 -2 -9 -8 18 -7 16 -9 -14 -19 -40 -14 -90 -16 -90 -2 0
5 -6 7 -14 4 -8 -3 -17 0 -22 7 -5 9 -2 10 9 6 10 -4 17 -1 17 6 0 9 2 9 8 0
11 -18 77 -21 77 -3 0 11 11 15 38 15 20 0 37 3 37 8 0 4 24 7 53 7 37 0 48 3
37 10 -11 7 -6 10 22 10 20 0 39 -6 41 -12 3 -7 6 30 6 84 l1 96 -55 4 -55 3
0 -33 c0 -79 -62 -140 -147 -144 l-38 -1 45 -7 45 -7 -59 -1 c-48 -2 -57 1
-48 10 10 10 5 19 -25 44 -34 29 -37 36 -41 95 l-4 64 -48 3 c-42 3 -48 0 -54
-20z m384 -120 c0 -9 4 -8 10 2 5 8 10 11 10 5 0 -16 -14 -30 -31 -31 -11 -1
-15 5 -12 17 6 21 23 26 23 7z m55 -18 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z m-122 -47 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m-73 -27 c0 -9 -40 -13 -46 -5 -3 5 6 9 20 9 14 0 26 -2 26
-4z"/>
					<path d="M3920 4275 l0 -85 27 17 c15 10 29 17 32 15 3 -2 9 5 13 15 6 12 7 8
3 -12 l-5 -30 18 33 18 32 23 -22 23 -23 -21 40 c-12 22 -21 51 -21 65 0 20
-5 26 -22 27 -13 1 -38 5 -55 8 l-33 6 0 -86z m77 39 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m20 -36 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z"/>
					<path d="M4352 4333 c-44 -3 -53 -7 -49 -21 4 -19 -20 -73 -37 -84 -8 -6 -8
-8 0 -8 18 0 41 33 49 68 4 22 12 32 25 32 24 0 31 -22 14 -45 -15 -20 -19
-45 -6 -45 4 0 13 14 20 30 8 17 20 30 28 30 8 0 11 6 8 15 -5 12 2 15 34 15
36 0 40 -2 34 -22 -3 -13 -1 -19 5 -15 6 4 13 16 16 27 4 16 0 20 -21 20 -15
0 -36 2 -47 3 -11 2 -44 2 -73 0z"/>
					<path d="M4511 4305 c0 -16 8 -43 19 -60 22 -33 27 -27 8 8 -6 12 -15 36 -19
52 l-7 30 -1 -30z"/>
					<path d="M4886 4297 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
					<path d="M4317 4260 c-8 -22 -11 -40 -6 -40 5 0 12 14 16 30 3 17 9 30 14 30
5 0 9 5 9 10 0 24 -18 7 -33 -30z"/>
					<path d="M4452 4290 c0 -8 4 -26 8 -40 6 -20 8 -21 8 -5 0 11 -3 29 -8 40 -5
12 -8 14 -8 5z"/>
					<path d="M4432 4270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M1850 4275 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
					<path d="M4786 4265 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
					<path d="M4380 4257 c0 -7 -11 -28 -25 -47 -31 -42 -33 -62 -2 -23 23 29 47
69 47 78 0 3 -4 5 -10 5 -5 0 -10 -6 -10 -13z"/>
					<path d="M1727 4187 c-27 -49 -27 -50 -4 -24 22 26 25 27 32 10 6 -14 9 -15
12 -4 4 10 10 8 25 -10 13 -16 11 -8 -8 29 -14 28 -27 52 -28 52 0 0 -14 -24
-29 -53z m43 0 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10
-19 10 -23z"/>
					<path d="M4017 4219 c-3 -13 -1 -19 6 -16 15 5 22 37 9 37 -5 0 -12 -9 -15
-21z"/>
					<path d="M4440 4236 c0 -4 9 -19 20 -33 11 -14 20 -21 20 -16 0 6 -9 20 -20
33 -11 13 -20 20 -20 16z"/>
					<path d="M3955 4200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M4090 4190 c-12 -8 -12 -10 3 -16 10 -3 21 -4 24 0 4 3 0 6 -8 6 -11
0 -11 2 1 10 8 5 11 10 5 10 -5 0 -17 -4 -25 -10z"/>
					<path d="M4158 4183 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
					<path d="M4633 4183 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
					<path d="M4153 4163 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
					<path d="M3748 4153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M3737 4124 c6 -15 23 -19 23 -5 0 5 -7 11 -14 14 -10 4 -13 1 -9 -9z" />
					<path d="M4015 4130 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M4090 4130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M1810 4111 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
					<path d="M4163 4103 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
					<path d="M1643 4044 c-18 -30 -28 -54 -23 -54 6 0 10 4 10 9 0 5 6 16 13 23
13 14 40 69 35 73 -2 2 -17 -21 -35 -51z"/>
					<path d="M1520 3973 c0 -5 11 -8 24 -8 13 0 22 3 19 8 -2 4 -13 7 -24 7 -10 0
-19 -3 -19 -7z"/>
					<path d="M1709 3883 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
					<path d="M1620 3839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
